/* ------------------- */
/* Custom properties   */
/* ------------------- */

:root {
	/* colors */
	--clr-dark: 230 35% 7%;
	--clr-light: 231 77% 90%;
	--clr-white: 0 0% 100%;

	/* font-sizes */
	--fs-900: 9.375rem;
	--fs-800: 6.25rem;
	--fs-700: 3.5rem;
	--fs-600: 2rem;
	--fs-500: 1.75rem;
	--fs-400: 1.3rem;
	--fs-300: 1rem;
	--fs-200: 0.875rem;

	/* font weight */
	--fw-700: 700;
	--fw-600: 600;
	--fw-500: 500;
	--fw-400: 400;
	--fw-300: 300;
	--fw-200: 200;

	/* primary colors */
	--background-color: hsl(0, 0%, 100%);
	--primary: 	hsl(274, 100%, 75%);
	--bg-secondary: hsl(229, 30%, 39%);
	--font-color: hsl(203, 9%, 18%);
	--gray-soft: hsl(210, 16%, 93%);
	--primary-without-hsl: 252deg, 92%, 74%;

	/* shadow */
	--shadow-color: 286deg 36% 56%;
	--shadow-elevation-medium:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);

}


/* ------------------- */
/* Reset               */
/* ------------------- */

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
	margin: 0;
}

/* set up the body */
body {
	line-height: 1.5;
	min-height: 100vh;
}

/* make images easier to work with */
img,
picutre {
	max-width: 100%;
	display: block;
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
	font: inherit;
}

/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {  
	*,
	*::before,
	*::after {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* ------------------- */
/* Utility classes     */
/* ------------------- */

.flex {
	display: flex;
	gap: var(--gap, 1rem);
}

.grid {
	display: grid;
	gap: var(--gap, 1rem);
}

.container {
	padding-inline: 2em;
	margin-inline: auto;
}

/* screen readers only */
/* hides the visual element but keeps it in the dom */
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap; /* added line */
	border: 0;
}

/* colors */

.bg-dark { background-color: hsl( var(--clr-dark) );}
.bg-accent { background-color: hsl( var(--clr-light) );}
.bg-white { background-color: hsl( var(--clr-white) );}

.text-dark { color: hsl( var(--clr-dark) );}
.text-accent { color: hsl( var(--clr-light) );}
.text-white { color: hsl( var(--clr-white) );}

/* Typography */
.letter-spacing-1 { letter-spacing: 1px; } 
.letter-spacing-2 { letter-spacing: 2.7px; } 
.letter-spacing-3 { letter-spacing: 2.35px; } 

.uppercase { text-transform: uppercase; }

.fs-900 { font-size: var(--fs-900); }
.fs-800 { font-size: var(--fs-800); }
.fs-700 { font-size: var(--fs-700); }
.fs-600 { font-size: var(--fs-600); }
.fs-500 { font-size: var(--fs-500); }
.fs-400 { font-size: var(--fs-400); }
.fs-300 { font-size: var(--fs-300); }
.fs-200 { font-size: var(--fs-200); }

.fw-700 { font-weight: var(--fw-700); }
.fw-600 { font-weight: var(--fw-600); }
.fw-500 { font-weight: var(--fw-500); }
.fw-400 { font-weight: var(--fw-400); }
.fw-300 { font-weight: var(--fw-300); }
.fw-200 { font-weight: var(--fw-200); }


.fs-900,
.fs-800,
.fs-700,
.fs-600 {
  line-height: 1.1;
}

p {
	font-size: var(--fs-400);
}

/* spacing */
/* first child has no margin top and last child has no margin bottom */
.flow > *:not(:first-child) {
	margin-top: 1rem;
}

.align-center {
	text-align: center;
}

.shadow-low {
	box-shadow: var(--shadow-elevation-low);
}

.bg {
	background-color: var(--background-color);
}
  
.font-color {
	color: var(--font-color);
} 

.primary-color {
	color: var(--primary);
}

.bg-secondary {
	background-color: var(--gray-soft);
}

.bg-primary-btn {
	background-color: var(--primary);
}
