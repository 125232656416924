@import '../NavBar/Theme/Vars.scss';

.intro-page {
    width: 100%;

    .intro-img {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        margin: 24px auto;
    }

    .quote {
        margin-top: 36px;
        .bio {
            font-weight: normal;
            font-size: 1.4rem;
        }
        h2 {
            font-weight: 600;
            font-size: 2rem;
        }
        h3 {
            margin-top: 8px;
            font-weight: normal;
            font-size: 1.4rem;
        }
    }

    .social-media-icons {
        margin-top: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-content: space-evenly;
        a {
            font-size: 2rem;
            color: $primary;
            transition: transform 0.3s ease-in-out;
            &:hover {
                transform: scale(1.2);
            }
        }
    }

}