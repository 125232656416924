@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap');
@import './components/NavBar/Theme/Vars.scss';

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  box-sizing: border-box;

  li {
    list-style: none
  };
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.App {
  transition: 
    font-size 0.25s ease-in, color 0.25s ease-in, 
    background-color 0.2s ease-in;
	
	.container {
		padding: 0 16px;
		@media (min-width: 780px) {
			max-width: 780px;
			margin: 0 auto;
		}
	}
}

[data-theme=Dark] {
	background-color: hsl(225, 59%, 11%);
	color: hsl(164, 100%, 96%);

	.primary-color {
		color: hsl(171, 100%, 72%);
	}

	.bg-secondary {
		background: hsl(225, 40%, 18%);
	}

	.bg-primary-btn {
		background: hsl(171, 59%, 50%);
	}
}

[data-theme=Soft] {
	background-color: hsl(54, 91%, 86%);
	color: hsl(203, 9%, 18%);

	.primary-color {
		color: hsl(332, 84%, 77%);
	}

	.bg-secondary {
		background: hsl(143, 62%, 89%);
	}

	.bg-primary-btn {
		background: hsl(332, 84%, 77%);
	}
}

[data-theme=Spooky] {
	background-color: hsl(265, 36%, 10%);
	color: hsl(300, 5%, 96%);

	.primary-color {
		color: hsl(19, 100%, 54%);
	}

	.bg-secondary {
		background: hsl(284, 60%, 24%);
	}

	.bg-primary-btn {
		background: hsl(19, 100%, 54%);
	}
}