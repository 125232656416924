@import './Theme/Vars.scss';

.portfolio-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    width: 100%;
    position: relative;

    .header-burger {

        .portfolio-header-x {
            top: 20px;
            right: 16px;
            position: absolute;
            z-index: 11;
            cursor: pointer;
            border: transparent;
            background: transparent;
            svg {
                font-size: 2rem;
            }
        }

        .portfolio-header-menu {
            top: 20px;
            cursor: pointer;
            position: absolute;
            border: transparent;
            background: transparent;
            z-index: 1;
            svg {
                font-size: 1.5rem;
            }
        }

        .nav-menu { 
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 40vh;
            clip-path: circle(100px at 90% -10%);
            -webkit-clip-path: circle(100px at 0 -10%);
            transition: all 0.3s ease-in-out;
            padding: 16px;
            z-index: -1;
            li {
                width: 100%;
                margin: 8px auto;
                .nav-links {
                    text-decoration: none;
                    font-size: 1.5rem;
                    letter-spacing: 3px;
                    position: relative;
                    transition: 0.2s ease-in-out;
                }
            }

            &.active {
                top: 0;
                z-index: 10;
                clip-path: circle(1000px at 90% -10%); 
                -webkit-clip-path: circle(1000px at 90% -10%);
            }

        }

        @media (min-width: 700px) {
            .portfolio-header-x {
                width: 30px;
            }
            .portfolio-header-menu {
                width: 30px;
            }
            .nav-menu{
                li{
                    margin: 15px auto;
                    .nav-links {
                        font-size: 55px;
                    }
                }
            }
        }
        
    }

    .paintBrush {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        border: transparent;
        position: absolute;
        right: 8px;
        cursor: pointer;
        background-color: $gray-soft;
    }
    
}
