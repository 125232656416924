@import url(https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;800&display=swap);
/* ------------------- */
/* Custom properties   */
/* ------------------- */

:root {
	/* colors */
	--clr-dark: 230 35% 7%;
	--clr-light: 231 77% 90%;
	--clr-white: 0 0% 100%;

	/* font-sizes */
	--fs-900: 9.375rem;
	--fs-800: 6.25rem;
	--fs-700: 3.5rem;
	--fs-600: 2rem;
	--fs-500: 1.75rem;
	--fs-400: 1.3rem;
	--fs-300: 1rem;
	--fs-200: 0.875rem;

	/* font weight */
	--fw-700: 700;
	--fw-600: 600;
	--fw-500: 500;
	--fw-400: 400;
	--fw-300: 300;
	--fw-200: 200;

	/* primary colors */
	--background-color: hsl(0, 0%, 100%);
	--primary: 	hsl(274, 100%, 75%);
	--bg-secondary: hsl(229, 30%, 39%);
	--font-color: hsl(203, 9%, 18%);
	--gray-soft: hsl(210, 16%, 93%);
	--primary-without-hsl: 252deg, 92%, 74%;

	/* shadow */
	--shadow-color: 286deg 36% 56%;
	--shadow-elevation-medium:
    0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);

}


/* ------------------- */
/* Reset               */
/* ------------------- */

/* https://piccalil.li/blog/a-modern-css-reset/ */

/* Box sizing */
*,
*::before,
*::after {
	box-sizing: border-box;
}

/* Reset margins */
body,
h1,
h2,
h3,
h4,
h5,
p,
figure,
picture {
	margin: 0;
}

/* set up the body */
body {
	line-height: 1.5;
	min-height: 100vh;
}

/* make images easier to work with */
img,
picutre {
	max-width: 100%;
	display: block;
}

/* make form elements easier to work with */
input,
button,
textarea,
select {
	font: inherit;
}

/* remove animations for people who've turned them off */
@media (prefers-reduced-motion: reduce) {  
	*,
	*::before,
	*::after {
		-webkit-animation-duration: 0.01ms !important;
		        animation-duration: 0.01ms !important;
		-webkit-animation-iteration-count: 1 !important;
		        animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

/* ------------------- */
/* Utility classes     */
/* ------------------- */

.flex {
	display: flex;
	grid-gap: 1rem;
	gap: 1rem;
	grid-gap: var(--gap, 1rem);
	gap: var(--gap, 1rem);
}

.grid {
	display: grid;
	grid-gap: 1rem;
	gap: 1rem;
	grid-gap: var(--gap, 1rem);
	gap: var(--gap, 1rem);
}

.container {
	padding-inline: 2em;
	margin-inline: auto;
}

/* screen readers only */
/* hides the visual element but keeps it in the dom */
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap; /* added line */
	border: 0;
}

/* colors */

.bg-dark { background-color: hsl( 230 35% 7%); background-color: hsl( var(--clr-dark) );}
.bg-accent { background-color: hsl( 231 77% 90%); background-color: hsl( var(--clr-light) );}
.bg-white { background-color: hsl( 0 0% 100%); background-color: hsl( var(--clr-white) );}

.text-dark { color: hsl( 230 35% 7%); color: hsl( var(--clr-dark) );}
.text-accent { color: hsl( 231 77% 90%); color: hsl( var(--clr-light) );}
.text-white { color: hsl( 0 0% 100%); color: hsl( var(--clr-white) );}

/* Typography */
.letter-spacing-1 { letter-spacing: 1px; } 
.letter-spacing-2 { letter-spacing: 2.7px; } 
.letter-spacing-3 { letter-spacing: 2.35px; } 

.uppercase { text-transform: uppercase; }

.fs-900 { font-size: 9.375rem; font-size: var(--fs-900); }
.fs-800 { font-size: 6.25rem; font-size: var(--fs-800); }
.fs-700 { font-size: 3.5rem; font-size: var(--fs-700); }
.fs-600 { font-size: 2rem; font-size: var(--fs-600); }
.fs-500 { font-size: 1.75rem; font-size: var(--fs-500); }
.fs-400 { font-size: 1.3rem; font-size: var(--fs-400); }
.fs-300 { font-size: 1rem; font-size: var(--fs-300); }
.fs-200 { font-size: 0.875rem; font-size: var(--fs-200); }

.fw-700 { font-weight: 700; font-weight: var(--fw-700); }
.fw-600 { font-weight: 600; font-weight: var(--fw-600); }
.fw-500 { font-weight: 500; font-weight: var(--fw-500); }
.fw-400 { font-weight: 400; font-weight: var(--fw-400); }
.fw-300 { font-weight: 300; font-weight: var(--fw-300); }
.fw-200 { font-weight: 200; font-weight: var(--fw-200); }


.fs-900,
.fs-800,
.fs-700,
.fs-600 {
  line-height: 1.1;
}

p {
	font-size: 1.3rem;
	font-size: var(--fs-400);
}

/* spacing */
/* first child has no margin top and last child has no margin bottom */
.flow > *:not(:first-child) {
	margin-top: 1rem;
}

.align-center {
	text-align: center;
}

.shadow-low {
	box-shadow: var(--shadow-elevation-low);
}

.bg {
	background-color: hsl(0, 0%, 100%);
	background-color: var(--background-color);
}
  
.font-color {
	color: hsl(203, 9%, 18%);
	color: var(--font-color);
} 

.primary-color {
	color: hsl(274, 100%, 75%);
	color: var(--primary);
}

.bg-secondary {
	background-color: hsl(210, 16%, 93%);
	background-color: var(--gray-soft);
}

.bg-primary-btn {
	background-color: hsl(274, 100%, 75%);
	background-color: var(--primary);
}

.intro-page{width:100%}.intro-page .intro-img{width:300px;height:300px;border-radius:50%;margin:24px auto}.intro-page .quote{margin-top:36px}.intro-page .quote .bio{font-weight:normal;font-size:1.4rem}.intro-page .quote h2{font-weight:600;font-size:2rem}.intro-page .quote h3{margin-top:8px;font-weight:normal;font-size:1.4rem}.intro-page .social-media-icons{margin-top:36px;display:flex;justify-content:center;align-items:center;justify-content:space-evenly}.intro-page .social-media-icons a{font-size:2rem;color:#9880fa;transition:-webkit-transform .3s ease-in-out;transition:transform .3s ease-in-out;transition:transform .3s ease-in-out, -webkit-transform .3s ease-in-out}.intro-page .social-media-icons a:hover{-webkit-transform:scale(1.2);transform:scale(1.2)}
.theme__container{position:absolute;left:0;opacity:0;max-width:100%;pointer-events:none}.theme__container.open{pointer-events:all;position:static;transition:all .2s ease-in-out;display:block;opacity:1}.theme__container .theme{width:100%;display:flex;overflow-x:scroll;background-color:rgba(0,0,0,0);justify-content:space-between}.theme__container .theme .theme__card{text-align:center;margin:8px;border:2px solid #9880fa;border-radius:5px;padding:4px 8px;cursor:pointer}.theme__container .theme .theme__card:focus{outline:1px solid #9880fa;outline-offset:2px}.theme__container .theme .theme__card .colors{padding:8px;display:flex}.theme__container .theme .theme__card .colors div{width:20px;height:20px;border-radius:50%;border:1px solid #fff}@media(min-width: 780px){.theme__container .theme{overflow-x:hidden}}
.portfolio-header{display:flex;justify-content:space-between;align-items:center;height:60px;width:100%;position:relative}.portfolio-header .header-burger .portfolio-header-x{top:20px;right:16px;position:absolute;z-index:11;cursor:pointer;border:rgba(0,0,0,0);background:rgba(0,0,0,0)}.portfolio-header .header-burger .portfolio-header-x svg{font-size:2rem}.portfolio-header .header-burger .portfolio-header-menu{top:20px;cursor:pointer;position:absolute;border:rgba(0,0,0,0);background:rgba(0,0,0,0);z-index:1}.portfolio-header .header-burger .portfolio-header-menu svg{font-size:1.5rem}.portfolio-header .header-burger .nav-menu{list-style:none;display:flex;flex-direction:column;justify-content:center;position:absolute;top:0;left:0;width:100%;height:40vh;clip-path:circle(100px at 90% -10%);-webkit-clip-path:circle(100px at 0 -10%);transition:all .3s ease-in-out;padding:16px;z-index:-1}.portfolio-header .header-burger .nav-menu li{width:100%;margin:8px auto}.portfolio-header .header-burger .nav-menu li .nav-links{text-decoration:none;font-size:1.5rem;letter-spacing:3px;position:relative;transition:.2s ease-in-out}.portfolio-header .header-burger .nav-menu.active{top:0;z-index:10;clip-path:circle(1000px at 90% -10%);-webkit-clip-path:circle(1000px at 90% -10%)}@media(min-width: 700px){.portfolio-header .header-burger .portfolio-header-x{width:30px}.portfolio-header .header-burger .portfolio-header-menu{width:30px}.portfolio-header .header-burger .nav-menu li{margin:15px auto}.portfolio-header .header-burger .nav-menu li .nav-links{font-size:55px}}.portfolio-header .paintBrush{border-radius:50%;width:42px;height:42px;border:rgba(0,0,0,0);position:absolute;right:8px;cursor:pointer;background-color:#eaedf0}
.technologies{margin-top:16px;position:relative;height:360px;border-radius:8px;background-color:#eaedf0;background-image:radial-gradient(circle at 13px 12px, #f77766 10px, transparent 10px),radial-gradient(circle at 13px 12px, #d6f76b 10px, transparent 10px),radial-gradient(circle at 13px 12px, #fde28a 10px, transparent 10px),linear-gradient(hsl(252deg, 79%, 77%), hsl(252deg, 92%, 74%));background-size:23px 23px,23px 23px,23px 23px,100% 55px,38px 100%,200px 20px,200px 20px,253px 20px,200px 20px,200px 20px;background-position:13px 16px,44px 16px,77px 16px,top left,top left,79px 110px,132px 150px,79px 190px,79px 230px,132px 270px;background-repeat:no-repeat,no-repeat,no-repeat,no-repeat,repeat-y,no-repeat}.technologies code{position:absolute;bottom:20px;left:16px;box-sizing:border-box}.technologies code span,.technologies code p{color:#9880fa;font-weight:500;font-size:1.1rem}.technologies code .variable{color:#2a2f32}.technologies code .name{color:#c880ff}
.section-info{margin:36px 0}.section-info h2{margin-bottom:16px}.section-info p{margin:8px 0}.section-info a{text-decoration:none}.section-info .btn-contact{border:rgba(0,0,0,0);width:100%;padding:16px;border-radius:5px;margin:24px 0;cursor:pointer;text-decoration:none;color:#fff;transition:-webkit-filter .2s ease-in-out;transition:filter .2s ease-in-out;transition:filter .2s ease-in-out, -webkit-filter .2s ease-in-out}.section-info .btn-contact:hover{-webkit-filter:brightness(1.05);filter:brightness(1.05)}
.experience{margin:16px 0}.experience .technologiesList{display:flex;justify-content:space-evenly;flex-wrap:wrap}.experience .technologiesList p{padding:5px;border-radius:5px}
body{font-family:"Inter",sans-serif;margin:0;padding:0;height:100vh;box-sizing:border-box}body li{list-style:none}*{box-sizing:border-box;margin:0;padding:0}.App{transition:font-size .25s ease-in,color .25s ease-in,background-color .2s ease-in}.App .container{padding:0 16px}@media(min-width: 780px){.App .container{max-width:780px;margin:0 auto}}[data-theme=Dark]{background-color:#0c142d;color:#ebfffa}[data-theme=Dark] .primary-color{color:#70ffea}[data-theme=Dark] .bg-secondary{background:#1c2540}[data-theme=Dark] .bg-primary-btn{background:#34cbb4}[data-theme=Soft]{background-color:#fcf5bb;color:#2a2f32}[data-theme=Soft] .primary-color{color:#f693c1}[data-theme=Soft] .bg-secondary{background:#d2f4df}[data-theme=Soft] .bg-primary-btn{background:#f693c1}[data-theme=Spooky]{background-color:#181023;color:#f5f4f5}[data-theme=Spooky] .primary-color{color:#ff5f14}[data-theme=Spooky] .bg-secondary{background:#4e1862}[data-theme=Spooky] .bg-primary-btn{background:#ff5f14}
