@import '../NavBar/Theme/Vars.scss';

.technologies {
    margin-top: 16px;
    $circle1Pos: 13px 16px;
    $circle2Pos: 44px 16px;
    $circle3Pos: 77px 16px;
    $circleSize: 23px 23px;
    position: relative;
    height: 360px;
    border-radius: 8px;
    background-color: $gray-soft;
    background-image: 
        radial-gradient(circle at 13px 12px, #f77766 10px, transparent 10px), 
        radial-gradient(circle at 13px 12px, #d6f76b 10px, transparent 10px),
        radial-gradient(circle at 13px 12px, #fde28a 10px, transparent 10px),
        linear-gradient(hsl(252, 79%, 77%), hsl(252, 92%, 74%));
    background-size: $circleSize, $circleSize, $circleSize, 100% 55px, 38px 100%, 200px 20px, 200px 20px, 253px 20px, 200px 20px, 200px 20px;
    background-position: $circle1Pos, $circle2Pos, $circle3Pos, top left, top left, 79px 110px, 132px 150px, 79px 190px, 79px 230px, 132px 270px;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-y, no-repeat;
    code {
        position: absolute;
        bottom: 20px;
        left: 16px;
        box-sizing: border-box;

        span, p {
          color: $primary; 
          font-weight: 500;
          font-size: 1.1rem;
        }

        .variable {
            color: $font-color;
        }

        .name {
            color: hsl(274, 100%, 75%);
        }
        
    }
}